<template>
  <div v-if="selectedBeneficiaryId === null" class="beneficary-page">
    <div class="header-beneficiaries">
      <div class="left">
        <h1>{{ $t("beneficiariesTitle") }}</h1>
        <p>{{ $t("beneficiariesDescription") }}</p>
      </div>
      <div class="right">
        <a href="#" class="info">
          <img src="@/assets/images/info-sign.svg" />
          <strong>{{ $t("beneficiariesInfoTitle") }}</strong>
          <div class="pop-hover">{{ $t("beneficiariesInfoMessage") }}</div>
        </a>
      </div>
    </div>
    <br clear="all" />
    <!-- filter-mobile -->
    <div class="container-alb filter-mobile">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("beneficiariesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("beneficiariesFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option v-for="(entry, index) in cardProducts" :key="index" :value="entry.card"
                :selected="isSelectedProduct(entry.card)">
                {{
                  getProductNameMethod(
                    entry.card === 0
                      ? entry.paper
                      : entry.card
                  )
                }}
              </option>
            </select>
          </td>
          <td>
            {{ $t("beneficiariesFilterCardStatus") }}
            <select class="filtrare" @change="cardStatusSelected">
              <option v-for="(entry, index) in cardStatuses" :key="index" :value="entry.value"
                :selected="isSelectedCardStatus(entry.value)">
                {{ $t("cardStatus_" + entry.descriptionCode) }}
              </option>
            </select>
          </td>
          <td>
            {{ $t("beneficiariesFilterCardType") }}
            <select class="filtrare" @change="cardTypeSelected">
              <option v-for="(entry, index) in cardTypes" :key="index" :value="entry.value"
                :selected="isSelectedCardStatus(entry.value)">
                {{ $t("cardType_" + entry.descriptionCode) }}
              </option>
            </select>
          </td>
          <td>
            {{ $t("beneficiariesFilterDeliveryAddress") }}
            <select class="filtrare" @change="addressFilterSelected">
              <option value="0" selected>{{ $t("cardsAction_CHOOSE") }}</option>
              <option v-for="(entry, index) in deliveryAddresses" :key="index" :value="entry.codcom"
                :selected="isSelectedFilterAddress(entry.codcom)">
                {{
                  entry.codcom +
                  " - " +
                  entry.address.street_name +
                  ", " +
                  entry.address.locality
                }}
              </option>
            </select>
          </td>
          <td v-if="this.userRole === 'FAC'">
            {{ $t("beneficiariesFilterProformNumber") }}
            <form @submit.prevent="
                                                                                                  {
              return false;
            }
              ">
              <input type="text" class="formedituser" v-model="filters.proformNumber"
                v-on:keyup.enter="loadCustomerBeneficiaries()" />
            </form>
          </td>
        </tr>
        <tr v-if="this.userRole === 'FAC'">
          <td colspan="3">
            {{ $t("beneficiariesFileUploadText") }}
          </td>
          <td colspan="2">
            <b-form-file v-model="fileReceived" ref="file" accept=".xls, .xlsx" :placeholder="$t('uploadFileLabel')"
              @input="uploadFile"></b-form-file>
          </td>
          <!-- <td>
            <div class="w-100 text-right">
              <a
                @click="fileReceived = null"
                class="status-background background-will-expire"
                >Reset</a
              >
            </div>
          </td> -->
        </tr>
      </table>
    </div>

    <div class="container-alb filter-mobile">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td width="40%">
            <p v-html='$t("beneficiariesUpdateInfoText")' />
          </td>
          <td>
            <b-form-file v-model="fileUpdateReceived" ref="file" accept=".xls, .xlsx" :placeholder="$t('uploadFileLabel')"
              @input="uploadUpdateFile"></b-form-file>
          </td>
          <!-- <td>
            <div class="w-100 text-right">
              <a
                @click="fileReceived = null"
                class="status-background background-will-expire"
                >Reset</a
              >
            </div>
          </td> -->
          <td valign="middle" width="200px" text-align="right">
            <a class="custom-file-label upload-file" @click="updateBeneficiariesData">
              <span>{{ $t("updateButtonLabel") }}</span>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a @click="fileUpdateReceived = null" class="status-background background-will-expire">Reset</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="container-alb moverflow">
      <table cellpadding="10" cellspacing="0" width="95%" align="center">
        <tr>
          <td>
            <h3>
              {{ $t("beneficiariesTitle") }}
              <a @click="getExcelBeneficiaries2()" class="custom-font-awesome notification-action-small cursor-pointer">
                <img class="download" src="@/assets/images/download.svg" />
                {{ $t('downloadBeneficiariesList') }}
              </a>

            </h3>
          </td>

          <td align="right">
            <div style="position: relative">
              <form @submit.prevent="
                {
                return false;
              }
                ">
                <input type="text" :placeholder="$t('beneficiariesFilterKeyword')" class="formedituser search-input"
                  v-model="filters.keyword" v-on:keyup.enter="loadCustomerBeneficiaries()" />
                <a class="search-btn cursor-pointer" @click="loadCustomerBeneficiaries()"></a>
              </form>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <a class="action-button selectAll" :class="this.isSelectedAllCustomerBeneficiaries && 'isActive'"
              @click="loadAndCheckAllBeneficiaries()">
              <span v-if="!this.isSelectedAllCustomerBeneficiaries">
                {{
                  $t("beneficiariesSelectAll") +
                  "&nbsp;(" +
                  this.selectedBeneficiaries.length +
                  ")"
                }}
              </span>
              <span v-else>
                {{
                  $t("beneficiariesDeselectAll") +
                  "&nbsp;(" +
                  this.selectedBeneficiaries.length +
                  ")"
                }}
              </span>
            </a>
          </td>
          <td>
            <a v-if="filters.product.card !== 11 && customerHasCardProductDejun()
                " class="action-button selectAll" :href="'/customer/new_order?product=' + filters.product.card">
              <span>
                {{
                  $t("orderBeneficiariesButtonLabel", {
                    product: filters.product.name
                  })
                }}
              </span>
            </a>
          </td>
        </tr>
      </table>

      <div class="loading" v-if="this.customerBeneficiaries === null">
        <img src="@/assets/images/loading.gif" />
      </div>
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table invoices-table" align="center"
        v-if="this.customerBeneficiaries !== null">
        <tr>
          <td valign="middle"></td>
          <td valign="middle">
            <strong>{{ $t("beneficiariesTableName") }}</strong>&nbsp;
            <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy('HDNAME')" :style="sortByField === 'HDNAME' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-0" valign="middle">
            <strong>{{ $t("beneficiariesTableSurname") }}</strong>&nbsp;
            <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy('HD1NAME')" :style="sortByField === 'HD1NAME' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("beneficiariesTableCNP") }}</strong>&nbsp;
            <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy('HDIDCARD')" :style="sortByField === 'HDIDCARD' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-2" valign="middle">
            <strong>{{ $t("beneficiariesTableCard") }}</strong>&nbsp;
          </td>
          <td class="priority-2" valign="middle">
            <strong>{{ $t("expireAtLabel") }}</strong>&nbsp;
          </td>
          <td class="priority-3" valign="middle">
            <strong>{{ $t("beneficiariesTableCardStatus") }}</strong>&nbsp;
            <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy('cstatus')" :style="sortByField === 'cstatus' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-4" valign="middle">
            <strong>{{ $t("beneficiariesTableAction") }}</strong>&nbsp;
          </td>
          <td class="priority-5" valign="middle">
            <strong>{{ $t("beneficiariesTableStatus") }}</strong>&nbsp;
            <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy('hstatus')" :style="sortByField === 'hstatus' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td valign="middle">&nbsp;</td>
        </tr>
        <tr v-if="this.customerBeneficiaries.length === 0">
          <td colspan="10">{{ $t("noBeneficiariesInfoMessage") }}</td>
        </tr>
        <template v-for="(entry, index) in this.customerBeneficiaries">
          <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
            <td valign="middle">
              <input v-if="entry.active" type="checkbox" @change="beneficiaryChecked($event.target, entry)"
                :checked="isBeneficiaryChecked(entry) >= 0" />
              <input v-else-if="entry.active === false && userRole === 'FAC'" type="checkbox"
                @change="beneficiaryChecked($event.target, entry)" :checked="isBeneficiaryChecked(entry) >= 0" />
            </td>
            <td valign="middle">
              <a class="detailsOrderMobile priority-show-5 display-inline-block" @click="selectBeneficiaryMobile(index)">
                <font-awesome-icon icon="minus-circle" v-if="detailsMobile && entry === selectedBeneficiary" />
                <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
              </a>
              <span>{{ entry.last_name }}</span>
            </td>
            <td class="priority-0" valign="middle">{{ entry.first_name }}</td>
            <td class="priority-1" valign="middle">{{ entry.cnp }}</td>
            <td class="priority-2" valign="middle">
              <img :src="require('@/assets/images/bullet_' +
                (entry.accounts[0].product > 1500 ? '15' : entry.accounts[0].product) +

                '.svg')
                " />
              &nbsp;&nbsp;{{ getProductNameMethod(entry.accounts[0].product) +  (entry.accounts[0].card.virtual ? " virtual" : "") }}
            </td>
            <td class="priority-3" valign="middle">
              <span >{{
               entry.accounts[0].card.expire }}</span>
            </td>
                        <td class="priority-3" valign="middle">
              <span class="status-background" :class="getClassForCardStatus(entry.accounts[0].card.state)">{{
                $t("cardStatus_" + entry.accounts[0].card.state) }}</span>
                
              </td>
              <td class="priority-4" valign="middle">
                <template v-for="(event, index) in  entry.accounts[0].card.events">
                    <span :key="index" class="status-background" :class="getClassForCardStatus(event.type)">{{
                  $t("cardStatus_" + event.type) }}</span>
                      </template>
                      
                <!-- <a class="info">
                  <img v-if="cardHasAction(entry.accounts[0].card.state)" :src="require('@/assets/images/action-' +
                  entry.accounts[0].card.state +
                  '.svg')
                  " />
                <div class="pop-hover">Actions</div>
              </a> -->
            </td>
            <td class="priority-5" valign="middle">
              <span class="status-background" :class="entry.active ? 'background-active' : 'background-suspended'
                ">
                {{
                  entry.active
                  ? $t("beneficiaryStatus_Active")
                  : $t("beneficiaryStatus_Inactive")
                }}
              </span>
            </td>
            <td valign="middle">
              <a class="action-button" @click="selectedBeneficiaryId = entry.id">
                <span>{{ $t("details") }}</span>
              </a>
            </td>
          </tr>
          <tr :key="customerBeneficiaries.length + index" class="child" :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && entry === selectedBeneficiary">
            <td colspan="12">
              <p class="priority-show-0">
                <strong>{{ $t("beneficiariesTableSurname") }} : &nbsp;&nbsp;</strong>
                <span>{{ entry.first_name }}</span>
              </p>
              <p class="priority-show-1">
                <strong>{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong>
                <span>{{ entry.cnp }}</span>
              </p>
              <p class="priority-show-2">
                <strong>{{ $t("beneficiariesTableCard") }} : &nbsp;&nbsp;</strong>
                <span>
                  <img :src="require('@/assets/images/bullet_' +
                    (entry.accounts[0].product > 1500 ? '15' : entry.accounts[0].product) +
                    '.svg')
                    " />
                  &nbsp;&nbsp;{{
                    getProductNameMethod(entry.accounts[0].product)
                  }}
                </span>
              </p>
              
              <p class="priority-show-3">
                <strong>{{ $t("expireAtLabel") }} :
                  &nbsp;&nbsp;</strong>
                <span >{{
                  entry.accounts[0].card.expire }}</span>
              </p>
              <p class="priority-show-3">
                <strong>{{ $t("beneficiariesTableCardStatus") }} :
                  &nbsp;&nbsp;</strong>
                <span class="status-background" :class="getClassForCardStatus(entry.accounts[0].card.state)">{{
                  $t("cardStatus_" + entry.accounts[0].card.state) }}</span>
              </p>
              <p class="priority-show-4">
                <strong>{{ $t("beneficiariesTableAction") }} : &nbsp;&nbsp;</strong>
                <span>
                  <a class="info">
                    <img v-if="cardHasAction(entry.accounts[0].card.state)" :src="require('@/assets/images/action-' +
                      entry.accounts[0].card.state +
                      '.svg')
                      " />
                    <div class="pop-hover">Actions</div>
                  </a>
                </span>
              </p>
              <p class="priority-show-5">
                <strong>{{ $t("beneficiariesTableStatus") }} : &nbsp;&nbsp;</strong>
                <span class="status-background" :class="entry.active ? 'background-active' : 'background-suspended'
                  ">
                  {{
                    entry.active
                    ? $t("beneficiaryStatus_Active")
                    : $t("beneficiaryStatus_Inactive")
                  }}
                </span>
              </p>
            </td>
          </tr>
        </template>
      </table>

      <div class="pagination-wrapper border-box">
        <div class="pagination-wrapper border-box">
          <b-pagination v-model="currentPage" :total-rows="this.beneficiariesCount" :per-page="perPage"
            size="sm"></b-pagination>
        </div>
      </div>
    </div>
    <div v-if="this.selectedBeneficiaries.length > 0" class="container-alb">
      <div class="wrapper-beneficiaries-actions">
        <br />
        <div>
          <div id="actionSelect">
            <b-row class="border-box">
              <b-col sm="5" md="3" class="border-box">
                <p>
                  {{ $t("actionsText", { count: this.selectedBeneficiaries.length }) }}
                </p>
              </b-col>
              <b-col sm="7" md="6" class="border-box">
                <select @change="actionSelected" class="filtrare">
                  <option v-for="(entry, index) in cardActions" :key="index" :value="entry"
                    :selected="selectedAction === entry">
                    {{ $t("cardsAction_" + entry) }}
                  </option>
                </select>
              </b-col>
            </b-row>
          </div>
          <br />
          <div id="addressActionSelect"
            :class="addressVisible ? 'visibility_visible' : 'visibility_hidden'">
            <b-row class="border-box" style="visibility: inherit">
              <b-col sm="5" md="3" class="border-box">
                <span>{{ $t("beneficiariesFilterDeliveryAddress") }}:</span>
              </b-col>
              <b-col sm="7" md="6" class="border-box">
                <select @change="addressActionSelected" class="filtrare">
                  <option v-for="(entry, index) in deliveryAddresses" :key="index" :value="entry.codcom"
                    :selected="selectedDeliveryAddress === entry.codcom">
                    {{
                      entry.codcom +
                      " - " +
                      entry.address.street_name +
                      ", " +
                      entry.address.locality
                    }}
                  </option>
                </select>
              </b-col>
            </b-row>
          </div>
          <br />
          <div :class="cardTypeVisible ? 'visibility_visible' : 'visibility_hidden'">
            <b-row class="border-box" style="visibility: inherit">
              <b-col sm="5" md="3" class="border-box">
                <span>{{ $t("beneficiariesTableCardType") }}:</span>
              </b-col>
              <b-col sm="7" md="6" class="border-box">
                <select v-model="defaultCardType" class="filtrare">
              <option value="">{{ $t('cardStatus_') }} </option>
              <option value="D">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                        <option value="P">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                      </select>
              </b-col>
            </b-row>
            
          </div>
          <br />
          <a class="status-background background-active" href="#" @click="validateSelection()">{{ $t("confirmButtonLabel")
          }}</a>
        </div>
      </div>
    </div>

    <!-- popup confirmare actiune  -->
    <div class="overlayer" v-if="this.openDetails">
      <div class="close-button-wrapper">
        <a class="close-button" @click="viewDetails(false)">
          <img src="@/assets/images/close-button.svg" />
        </a>
      </div>
      <div class="container-alb moverflow mx-auto">
        <div class="header-order-details">
          <div class="order-details-title">
            <h1>{{ $t("cardsAction_" + this.selectedAction) }}</h1>
            <p>{{ $t("actionConfirmMessage") }}</p>
          </div>
          <div class="right">
            <a class="notification-action" href="#" @click="submitAction()">{{
              $t("confirmButtonLabel")
            }}</a>
          </div>
        </div>
        <br clear="all" />
        <div class="header-table">
          <div class="left">
            <h3>
              {{
                $t("beneficiariesName") +
                " (" +
                this.selectedBeneficiaries.length +
                ")"
              }}
            </h3>
          </div>
        </div>
        <table id="selectedBeneficiariesListDisplay" cellpadding="10" cellspacing="0" width="95%" class="hover-table"
          align="center">
          <tr>
            <td valign="middle">
              <strong>{{ $t("beneficiariesTableName") }}</strong>&nbsp;
              <img src="@/assets/images/arrow-down-fill.svg" />
            </td>
            <td valign="middle">
              <strong>{{ $t("beneficiariesTableSurname") }}</strong>&nbsp;
              <img src="@/assets/images/arrow-down-fill.svg" />
            </td>
            <td class="priority-3" valign="middle">
              <strong>{{ $t("beneficiariesTableCNP") }}</strong>&nbsp;
              <img src="@/assets/images/arrow-down-fill.svg" />
            </td>
            <td class="priority-4" valign="middle">
              <strong>{{ $t("beneficiariesTableCardStatus") }}</strong>&nbsp;
            </td>
            <td valign="middle">
              <strong>{{ $t("beneficiariesTableAction") }}</strong>&nbsp;
            </td>
          </tr>
          <template v-for="(beneficiary, index) in this.selectedBeneficiaries">
            <tr :key="index" class="trhover" :class="index % 2 === 0 && 'gri-cell'">
              <td valign="middle">
                <a class="detailsOrderMobile priority-show-4 display-inline-block"
                  @click="selectBeneficiaryMobile(index)">
                  <font-awesome-icon icon="minus-circle" v-if="detailsMobile && beneficiary === selectedBeneficiary" />
                  <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                </a>
                <span>{{ beneficiary.last_name }}</span>
              </td>
              <td valign="middle">{{ beneficiary.first_name }}</td>
              <td class="priority-3" valign="middle">{{ beneficiary.cnp }}</td>
              <td class="priority-4" valign="middle">
                <span class="status-background" :class="getClassForCardStatus(beneficiary.accounts[0].card.state)
                  ">{{
    $t("cardStatus_" + beneficiary.accounts[0].card.state)
  }}</span>
              </td>
              <td valign="middle">
                <a class="see-more" @click="removeBeneficiary(beneficiary)">
                  <font-awesome-icon icon="trash-alt" size="2x" />
                </a>
              </td>
            </tr>
            <tr :key="selectedBeneficiaries.length + index" class="child" :class="index % 2 === 0 && 'gri-cell'"
              v-show="detailsMobile && beneficiary === selectedBeneficiary">
              <td colspan="12">
                <p class="priority-show-3">
                  <strong>{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong>
                  <span>{{ beneficiary.cnp }}</span>
                </p>
                <p class="priority-show-4">
                  <strong>{{ $t("beneficiariesTableCardStatus") }} :
                    &nbsp;&nbsp;</strong>
                  <span class="status-background" :class="getClassForCardStatus(beneficiary.accounts[0].card.state)
                    ">{{
    $t("cardStatus_" + beneficiary.accounts[0].card.state)
  }}</span>
                </p>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>

        <p v-if="this.error">
          {{ $t("errorActionBeneficiary_" + this.error.code) }}
        </p>
        <div v-if="this.actionOrderErrors" class="scroll-popup">
          <p v-for="(error, index) in this.actionOrderErrors" :key="index" class="trhover"
            :class="index % 2 === 0 && 'gri-cell'">
            {{ $t(error.error_code, { name: error.description }) }}
          </p>
        </div>
        <p v-if="this.actionSuccess" v-html="$t('actionSuccessMessage', {
          action: $t('cardsAction_' + this.selectedAction)
        })
          "></p>
        <div v-if="this.updateErrors" class="scroll-popup">
          <p v-for="(error, index) in this.updateErrors" :key="index" class="trhover"
            :class="index % 2 === 0 && 'gri-cell'">
            {{ getErrorDetails(error) }}
          </p>
        </div>
        <p v-if="this.beneficiariesUpdateSuccess" v-html="$t('beneficiariesUpdateMessage')
          "></p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_review" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_review', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("reviewBeneficiariesText") }}</p>
        <a class="button-orange" @click="readReviewBeneficiaries()">{{
          $t("nextStepButtonLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_virtual" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_virtual', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="defaultCardType === 'D' ? $t('virtualBeneficiariesText') : $t('physicalBeneficiariesTextRepl')"></p>
        <a class="button-orange" @click="readVirtualBeneficiaries()">{{
          $t("nextStepButtonLabel")
        }}</a>
      </div>
    </div>
  </div>

  <div v-else>
    <h3 class="return">
      <a href="#" @click="selectedBeneficiaryId = null">
        <font-awesome-icon icon="chevron-left" />
        <span>{{ $t("backButtonLabel") }}</span>
      </a>
    </h3>
    <BeneficiaryDetails :beneficiaryId="selectedBeneficiaryId" :deliveryAddresses="this.deliveryAddresses"
      :customer="this.customer" :userRole="this.userRole" :user="this.user" @personChanged="personChanged" />
  </div>
</template>
<script>
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceCompany from "@/http/http-user.js";
import BeneficiaryDetails from "@/components/company/customer/BeneficiaryDetails.vue";
import utils from "@/plugins/utils.js";
import { BPagination, BRow, BCol, BFormFile } from "bootstrap-vue";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";

export default {
  components: {
    BeneficiaryDetails,
    "b-pagination": BPagination,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-file": BFormFile
  },
  props: {
    customer: Object,
    msg: String,
    user: Object,
    userRole: String
  },

// COMMENT PT CACHE
// COMMENT PT CACHE
// COMMENT PT CACHE
// COMMENT PT CACHE
// COMMENT PT CACHE



  data() {
    return {
      accessToken: null,
      customerBeneficiaries: null,
      selectedBeneficiary: null,
      detailsMobile: null,
      excelBeneficiaries: null,
      selectedBeneficiaries: [],
      deliveryAddresses: null,
      error: null,
      updateErrors: null,
      actionOrderErrors: null,
      fileReceived: null,
      fileUpdateReceived: null,
      uploadFileContent: null,
      defaultCardType: "",
      filters: {
        product: 11,
        beneficiaryStatus: null,
        cardStatus: "",
        cardType: "",
        deliveryAddress: null,
        keyword: "",
        proformNumber: null,
        content: null
      },
      excelFields: {
        Prenume: "first_name",
        Nume: "last_name",
        CNP: "cnp",
        Telefon: "mobile_phone",
        PAN: {
          field: "accounts",
          callback: value => {
            return value[0].card.pan;
          }
        },
        Produs: {
          field: "accounts",
          callback: value => {
            return this.getProductNameMethod(value[0].product);
          }
        },
        Expira: {
          field: "accounts",
          callback: value => {
            return value[0].card.expire;
          }
        },
        StatusCard: {
          field: "accounts",
          callback: value => {
            return this.$t("cardStatus_" + value[0].card.state);
          }
        }
      },
      selectedBeneficiaryId: null,
      selectedAction: "CHOOSE",
      addressVisible: false,
      cardTypeVisible: false,
      selectedDeliveryAddress: null,
      cardActions: [
        "CHOOSE",
        "ACT",
        "REPL",
        "VALIDATE_REPL",
        "CANCEL_REPL",
        "PIN",
        "VALIDATE_PIN",
        "CANCEL_PIN",
        "CLO",
        "VALIDATE_CLO",
        "CANCEL_CLO",
        "VALIDATE_REN",
        "C_REN",
        "DELETE_BENEFICIARY",
        "SUSPEND_BENEFICIARY"
      ],
      openDetails: false,
      cardTypes: [
        {
          value: "",
          descriptionCode: ""
        },
        {
          value: "D",
          descriptionCode: "D"
        },
        {
          value: "P",
          descriptionCode: "P"
        },
      ],
      cardStatuses: [
        {
          value: "",
          descriptionCode: ""
        },
        {
          value: "A",
          descriptionCode: "A"
        },
        {
          value: "B,F",
          descriptionCode: "B"
        },
        {
          value: "D",
          descriptionCode: "D"
        },
        {
          value: "C",
          descriptionCode: "C"
        },
        {
          value: "X",
          descriptionCode: "X"
        },
        {
          value: "L",
          descriptionCode: "L"
        },
        {
          value: "E,I,M,N,O,P,R,S",
          descriptionCode: "E"
        },
        {
          value: "RN",
          descriptionCode: "RN"
        },
        {
          value: "RC,RV",
          descriptionCode: "RCV"
        },
        {
          value: "RC",
          descriptionCode: "RC"
        },
        {
          value: "RV",
          descriptionCode: "RV"
        },
        {
          value: "RM",
          descriptionCode: "RM"
        },
        {
          value: "RP",
          descriptionCode: "RP"
        },
        {
          value: "RNY",
          descriptionCode: "RNY"
        },
        {
          value: "RNN",
          descriptionCode: "RNN"
        },
      ],
      actionSuccess: false,
      perPage: 10,
      currentPage: 1,
      beneficiariesCount: 10,
      sortByField: "HDNAME",
      reverse: false,
      isSelectedAllCustomerBeneficiaries: false,
      reviewBeneficiaryBanner: false,
      beneficiariesUpdateSuccess: false,
    };
  },
  methods: {
    selectBeneficiaryMobile(index) {
      if (this.openDetails) {
        this.selectedBeneficiary = this.selectedBeneficiaries[index];
      } else {
        this.selectedBeneficiary = this.customerBeneficiaries[index];
      }

      this.detailsMobile = !this.detailsMobile;
    },
    uploadFile() {
      var reader = new FileReader();
      reader.onload = e => {
        if (!e) {
          this.filters.content = btoa(reader.content);
        } else {
          this.filters.content = btoa(e.target.result);
          this.loadCustomerBeneficiaries();
        }
      };
      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.readAsBinaryString(this.fileReceived);
      } else {
        alert("This browser does not support HTML5.");
      }

      if (!this.fileReceived) {
        return;
      }
      // */
    },
    uploadUpdateFile() {
      var reader = new FileReader();
      reader.onload = e => {
        if (!e) {
          this.uploadFileContent = btoa(reader.content);
        } else {
          this.uploadFileContent = btoa(e.target.result);
        }
      };
      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.readAsBinaryString(this.fileUpdateReceived);
      } else {
        alert("This browser does not support HTML5.");
      }

      if (!this.fileUpdateReceived) {
        return;
      }
      // */
    },
    loadCustomerBeneficiaries() {
      if (this.perPage < 5000) {
        this.customerBeneficiaries = null;
      }
      // retreive customer beneficiaries from service
      var formData = {
        products: [this.filters.product.card],
        per_page: this.perPage,
        page: this.currentPage
      };
      if (this.filters.beneficiaryStatus) {
        formData.holder_status = this.filters.beneficiaryStatus;
      }
      if (this.filters.cardStatus !== "") {
        formData.card_statuses = this.filters.cardStatus.split(",");
      } 
      if (this.filters.cardType !== "") {
        formData.card_type = this.filters.cardType;
      }
      if (this.filters.proformNumber) {
        formData.proform_number = this.filters.proformNumber;
      }

      if (this.filters.deliveryAddress && this.filters.deliveryAddress > 0) {
        formData.address_code = this.filters.deliveryAddress;
      }
      if (this.filters.keyword) {
        formData.keyword = this.filters.keyword.trim();
      }
      if (this.filters.content) {
        formData.content = this.filters.content.trim();
      }
      if (this.reverse) {
        formData.sort = "desc";
        formData.desc = [this.sortByField];
      } else {
        formData.sort = "asc";
        formData.asc = [this.sortByField];
      }

      httpServiceCustomer.customerService
        .getBeneficiariesByFile(
          formData,
          this.customer.company_id,
          this.accessToken
        )
        .then(function (response) {
          return response;
        })
        .catch(error => { })
        .then(response => {
          if (response.status == 200) {
            if (this.perPage > 5000) {
              this.selectedBeneficiaries = response.data;
              this.perPage = 10;
            } else {
              this.customerBeneficiaries = response.data;
            }
            this.beneficiariesCount = response.headers["x-up-count"]; //["Content-Length"];
          } else if (response.status == 400 || response.status == 403) {
            this.customerBeneficiaries = null;
          }
        });
    },
    updateBeneficiariesData() {
      if (!this.uploadFileContent) {
        this.updateErrors = [{ description: "", error_code: "BENEFICIARIES.UPDATE.FILE.EMPTY" }];
        this.showModalPopup('modal_popup_info', true)
        return;
      }
      this.showModalPopup('modal_popup_loading', true);

      httpServiceCustomer.customerService
        .updateBeneficiariesByFile(
          { content: this.uploadFileContent },
          this.customer.company_id,
          this.accessToken
        )
        .then(function (response) {
          return response;
        })
        .catch(error => { })
        .then(response => {
          if (response.status == 200) {
            this.updateErrors = response.data;
            if (this.updateErrors.beneficiaries) {
              this.beneficiariesUpdateSuccess = true;
              this.updateErrors = null;
            }
          } else {
            this.beneficiariesUpdateSuccess = false;
            this.updateErrors = [{ description: "", error_code: "BENEFICIARIES.UPDATE.ERROR" }]
          }

          this.showModalPopup('modal_popup_loading', false);
          this.showModalPopup('modal_popup_info', true);

        });
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.loadCustomerBeneficiaries();
    },
    loadDeliveryAddresses() {
      // retreive customer delivery from service
      var formData = "?brand=" + this.filters.product.brand;
      formData += "&environment=card";
      formData += "&type=customer";
      formData += "&per_page=" + 1000 + "&page=" + 1;

      httpServiceCompany.userService
        .getAddressPoints(this.customer.company_id, formData, this.accessToken)
        .then(function (response) {
          return response;
        })
        .catch(error => { })
        .then(response => {
          if (response.status == 200) {
            this.deliveryAddresses = response.data;
            this.selectedDeliveryAddress = this.deliveryAddresses[0].codcom;
            this.filters.deliveryAddress = 0; // this.deliveryAddresses[0].codcom;
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
          }
        });
    },
    isSelectedProduct(product) {
      return product === this.filters.product.card;
    },
    isSelectedCardStatus(status) {
      return status === this.filters.cardStatus;
    },
    isSelectedFilterAddress(addressCode) {
      return addressCode === this.filters.deliveryAddress;
    },
    productSelected(event) {
      this.filters.product = utils.getProductById(
        parseInt(event.target.value),
        this.customer.products
      );
      this.selectProduct()
    },
    selectProduct(){
      this.selectedBeneficiaries = [];
      this.isSelectedAllCustomerBeneficiaries = false;
      this.addressVisible = false;
      this.cardTypeVisible = false;

      if (this.filters.product.card === 12 && !this.filters.product.active) {
        this.cardActions = [
          "CHOOSE",
          "ACT",
          "REPL",
          "VALIDATE_REPL",
          "CANCEL_REPL",
          "PIN",
          "VALIDATE_PIN",
          "CANCEL_PIN",
          "CLO",
          "VALIDATE_CLO",
          "CANCEL_CLO",
          "DELETE_BENEFICIARY",
          "SUSPEND_BENEFICIARY"
        ]
      } else {
        this.cardActions = [
          "CHOOSE",
          "ACT",
          "REPL",
          "VALIDATE_REPL",
          "CANCEL_REPL",
          "PIN",
          "VALIDATE_PIN",
          "CANCEL_PIN",
          "CLO",
          "VALIDATE_CLO",
          "CANCEL_CLO",
          "VALIDATE_REN",
          "C_REN",
          "DELETE_BENEFICIARY",
          "SUSPEND_BENEFICIARY"
        ]
      }
      if (
      this.customer.block_cards
        // || (this.customer.state_owned && this.filters.product.card === 12)
        // || this.userRole === "FAC"
      ) {
        this.cardActions.push("BLOK");
      }

      this.loadCustomerBeneficiaries();
    },
    cardStatusSelected(event) {
      this.filters.cardStatus = event.target.value;
      this.selectedBeneficiaries = [];
      this.isSelectedAllCustomerBeneficiaries = false;

      this.loadCustomerBeneficiaries();
    },
    cardTypeSelected(event) {
      this.filters.cardType = event.target.value;
      this.selectedBeneficiaries = [];
      this.isSelectedAllCustomerBeneficiaries = false;

      this.loadCustomerBeneficiaries();
    },
    addressFilterSelected(event) {
      this.filters.deliveryAddress = event.target.value;
      this.selectedBeneficiaries = [];
      this.isSelectedAllCustomerBeneficiaries = false;

      this.loadCustomerBeneficiaries();
    },
    addressActionSelected(event) {
      this.selectedDeliveryAddress = event.target.value;
    },
    actionSelected(event) {
      this.actionOrderErrors = null;
      if (event == null) {
        this.selectedAction = "CHOOSE"
      } else {
        this.selectedAction = event.target.value;
      }
      this.defaultCardType = "";
      switch (this.selectedAction) {
        case "REPL":
        case "VALIDATE_REPL":
        case "VALIDATE_REN":
          if (!this.fileReceived) {
            this.addressVisible = true;
          }
          break;
          case "REPL":
        case "VALIDATE_REPL":
        // case "VALIDATE_REN":
          if (!this.fileReceived) {
            this.cardTypeVisible = true;
          }
          break;
        case "PIN":
        case "VALIDATE_PIN":
          // if not PIN by IVR
          if (!this.filterProductPinsModeIVR) {
            if (!this.fileReceived !== null) {
              this.addressVisible = true;

            }
          } else {
            this.addressVisible = false;

          }
          break;
        default:
          this.addressVisible = false;
          this.cardTypeVisible = false;


      }
    },
    getClassForCardStatus(status) {
      switch (status) {
        case "A":
          return "background-active";
        case "B":
        case "F":
        case "RC":
          return "background-blocked";
        case "D":
          return "background-deactivated";
        case "C":
        case "X":
        case "RNN":
          return "background-inactive";
        case "J":
        case "L":
          return "background-cancelled";
        case "E":
        case "I":
        case "M":
        case "N":
        case "O":
        case "P":
        case "R":
        case "S":
        case "RN":
        case "RNV":
        case "RNY":
        case "RP":
          return "background-will-expire";

        default:
          return "background-active";
      }
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    async getExcelBeneficiaries() {
      // retreive customer beneficiaries from service

      var formData = "?product=" + this.filters.product.card;
      if (this.filters.beneficiaryStatus) {
        formData += "&beneficiary_status=" + this.filters.beneficiaryStatus;
      }
      if (this.filters.cardStatus) {
        formData += "&card_status=" + this.filters.cardStatus;
      }
      if (this.filters.deliveryAddress !== null) {
        formData += "&delivery_address=" + this.filters.deliveryAddress;
      }
      if (this.filters.keyword) {
        formData += "&keyword=" + this.filters.keyword;
      }
      formData += "&per_page=" + 100000 + "&page=" + 1;

      httpServiceCustomer.customerService
        .searchCards(formData, this.customer.company_id, this.accessToken)
        .then(function (response) {
          return response;
        })
        .catch(error => { })
        .then(response => {
          if (response.status == 200) {
            this.excelBeneficiaries = response.data;
            this.$refs.download.$el.click();
          } else {
            return null;
          }
        });
    },
    personChanged() {
      this.selectedBeneficiary = null;
      this.selectedBeneficiaryId = null;
      this.loadCustomerBeneficiaries();
    },
    getExcelBeneficiaries2() {
      // retreive customer beneficiaries from service
      var formData = "?product=" + this.filters.product.card;
      if (this.filters.beneficiaryStatus) {
        formData += "&beneficiary_status=" + this.filters.beneficiaryStatus;
      }
      if (this.filters.cardStatus) {
        formData += "&card_status=" + this.filters.cardStatus;
      }
      if (this.filters.deliveryAddress !== null) {
        formData += "&delivery_address=" + this.filters.deliveryAddress;
      }
      if (this.filters.keyword) {
        formData += "&keyword=" + this.filters.keyword;
      }
      formData += "&per_page=" + 100000 + "&page=" + 1;
      this.showModalPopup("modal_popup_loading", true);
      return httpServiceCustomer.customerService
        .getCardsFile(formData, this.customer.company_id, this.accessToken)
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.name);
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
            this.error = { code: 500 };
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 404) {
            // show file not found
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_info", true);
          } else {
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_info", true);
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch(error => {
          this.error = { code: 500 };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    isBeneficiaryChecked(entry) {
      for (let index in this.selectedBeneficiaries) {
        if (entry.id === this.selectedBeneficiaries[index].id) {
          return index;
        }
      }
      return -1;
    },
    beneficiaryChecked(element, entry) {
      let lenBen = this.selectedBeneficiaries.length;

      if (element.checked) {
        for (let index in this.selectedBeneficiaries) {
          if (entry.id === this.selectedBeneficiaries[index].id) {
            return;
          }
        }
        entry.card_virtual = entry.accounts[0].card.virtual ? "D" : "P";
        this.selectedBeneficiaries.push(entry);
      } else {
        for (let index in this.selectedBeneficiaries) {
          if (entry.id === this.selectedBeneficiaries[index].id) {
            this.selectedBeneficiaries.splice(index, 1);
          }
        }
      }
      if (lenBen === 0) {
        this.actionSelected();
      }
    },
    removeBeneficiary(entry) {
      for (let index in this.selectedBeneficiaries) {
        if (entry.id === this.selectedBeneficiaries[index].id) {
          this.selectedBeneficiaries.splice(index, 1);
        }
      }
      if (this.selectedBeneficiaries.length === 0) {
        this.viewDetails(false);
      }
    },
    viewDetails(open) {
      this.openDetails = open;
    },
    customerHasCardProductDejun() {
      let hasProduct = false;
      this.customer.products.forEach(prod => {
        if (prod.card === 11) {
          hasProduct = true;
        }
      });
      return hasProduct;
    },
    submitAction() {
      this.showModalPopup("modal_popup_loading", true);
      let details = [];
      this.actionOrderErrors = null;
      this.error = null;
      this.actionOrderErrors = null;
      for (let index in this.selectedBeneficiaries) {
        let newBen = {
          card_id: this.selectedBeneficiaries[index].accounts[0].card.id,
          address:
            this.fileReceived !== null
              ? this.selectedBeneficiaries[index].accounts[0].card.addressCode
              : this.selectedDeliveryAddress === null
                ? this.deliveryAddresses[0].codcom
                : this.selectedDeliveryAddress,
                card_virtual: this.selectedBeneficiaries[index].card_virtual ? this.selectedBeneficiaries[index].card_virtual === "D" : false
        };
        if (this.selectedBeneficiaries[index].for_update) {
          newBen.first_name = this.selectedBeneficiaries[index].first_name;
          newBen.last_name = this.selectedBeneficiaries[index].last_name;
        }

        details.push(newBen);
      }
      let cardsActionData = {
        id: 0,
        type: this.selectedAction, //"PIN" reemitere PIN
        //"REPL" reemitere CARD
        customer_id: this.customer.company_id,
        product: this.filters.product.card,
        details: details
      };
      httpServiceCustomer.customerService
        .cardsAction(
          cardsActionData,
          this.accessToken,
          this.userRole === "FAC" ? this.user.username : null
        )
        .then(response => {
          if (response.status === 200) {
            this.actionSuccess = true;
            this.error = null;
            this.selectedBeneficiaries = null;
            this.viewDetails(false);
            this.selectedBeneficiaries = [];
            this.loadCustomerBeneficiaries();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response.data.code) {
            this.error = error.response.data;
            this.actionSuccess = false;
          } 
          // else if (error.response.status === 409) {
          //   this.error = { code: "409" };
          // } else {
          //   this.error = { code: "500" };
          // }
          // this.showModalPopup("modal_popup_loading", false);
          this.getResponseErrors(error.response);
          this.viewDetails(false);
        });
    },
    getErrorDetails(error) {
      let desc = error.description.split("#");
      return this.$t(error.error_code, {
        name: error.from_source_id,
        field: desc[0],
        info: desc[1]
      });
    },
    getResponseErrors(response) {
      let errorsLink = response.headers["link"].split(";")[0];
      errorsLink = errorsLink.replace(">", "");
      errorsLink = errorsLink.replace("<", "");
      if (errorsLink.includes("exceptions_type")) {
        httpServiceCustomer.customerService
          .getOrderErros(errorsLink, this.accessToken)
          .then(response => {
            if (response.status === 200) {
              this.actionOrderErrors = response.data.errors;
              this.actionOrderErrors.sort(
                (a, b) =>
                  parseFloat(a.from_source_id) - parseFloat(b.from_source_id)
              );
              this.error = { code: "ERROR" };
            }
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          })
          .catch(error => {
            if (error.response.data) {
              this.actionSuccess = false;
              this.error = { code: error.response.data.code };

              this.viewDetails(false);
              this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            }
          });
      }
    },
    validateSelection() {
      if (this.selectedAction === "CHOOSE") {
        this.error = { code: this.selectedAction };
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      if (
        this.selectedAction === "REPL" ||
        this.selectedAction === "VALIDATE_REPL" ||
        this.selectedAction === "VALIDATE_REN"
      ) {
        if (this.selectedDeliveryAddress === null) {
          this.error = { code: "NOADDRESS" };
          this.showModalPopup("modal_popup_info", true);
          return;
        }
      } else if (
        this.selectedAction === "PIN" ||
        this.selectedAction === "VALIDATE_PIN"
      ) {
        // if not PIN by IVR
        if (this.selectedDeliveryAddress === null) {
          this.error = { code: "NOADDRESS" };
          this.showModalPopup("modal_popup_info", true);
          return;
        }
      }
      this.viewDetails(true);
    },
    showModalPopup(elementId, show, entity = null) {
      if (show) {
        this.choosenAccount = entity;
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        this.choosenAccount = null;
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    checkAllBeneficiaries(element) {
      if (element.checked) {
        for (let index in this.customerBeneficiaries) {
          let selectedIndex = this.isBeneficiaryChecked(
            this.customerBeneficiaries[index]
          );
          if (selectedIndex < 0) {
            this.customerBeneficiaries[index].card_virtual = this.customerBeneficiaries[index].accounts[0].card.virtual ? "D" : "P";
            this.selectedBeneficiaries.push(this.customerBeneficiaries[index]);
          }
        }
      } else {
        for (let index in this.customerBeneficiaries) {
          let selectedIndex = this.isBeneficiaryChecked(
            this.customerBeneficiaries[index]
          );
          if (selectedIndex >= 0) {
            this.selectedBeneficiaries.splice(selectedIndex, 1);
          }
        }
      }
    },
    loadAndCheckAllBeneficiaries() {
      this.perPage = 10000;
      this.isSelectedAllCustomerBeneficiaries = !this
        .isSelectedAllCustomerBeneficiaries;
      if (this.isSelectedAllCustomerBeneficiaries) {
        if (this.userRole !== "FAC") {
          this.filters.beneficiaryStatus = "A";
        }
        this.loadCustomerBeneficiaries();
      } else {
        this.selectedBeneficiaries = [];
      }
    },
    cardHasAction(state) {
      switch (state) {
        case "B":
        case "F":
        case "D":
        case "RC":
        case "RP":
        case "RNY":
        case "RN":
          return true;
        default:
          return false;
      }
    },
    // cardInfo(cardid) {
    //   for (let index in this.selectedBeneficiaries) {
    //     if (
    //       this.selectedBeneficiaries[index].accounts[0].card.id + "" ===
    //       cardid
    //     ) {
    //       return (
    //         this.selectedBeneficiaries[index].first_name +
    //         " " +
    //         this.selectedBeneficiaries[index].last_name
    //       );
    //     }
    //   }
    //   return null;
    // },
    readReviewBeneficiaries() {
      Cookies.set("reviewBeneficiaries", true);
      this.showModalPopup("modal_popup_review", false);
    },
    readVirtualBeneficiaries() {
      Cookies.set("virtualBeneficiaries", true);
      this.showModalPopup("modal_popup_virtual", false);
    },
    setDefaultCardTypeForReplacement() {
      if (this.defaultCardType !== ""){      
        this.showModalPopup("modal_popup_virtual", true);
    }
      if (this.selectedBeneficiaries !== null) {
        for (let index in this.selectedBeneficiaries) {
          this.selectedBeneficiaries[index].card_virtual =
            this.defaultCardType;
        }
      }
    },
  },
  computed: {
    cardProducts() {
      let cardProducts = [];
      for (let index in this.customer.products) {
        if (this.customer.products[index].card > 0) {
          cardProducts.push(this.customer.products[index]);
        }
      }
      return cardProducts;
    },
    filterProductPinsModeIVR() {
      for (var index in this.customer.products) {
        if (this.customer.products[index].card === this.filters.product.card) {
          return this.customer.products[index].pinsMode === "IVR";
        }
      }
      return false;
    },
    
  },
  watch: {
    defaultCardType: function () {
      this.setDefaultCardTypeForReplacement();
    },
    currentPage: function () {
      this.loadCustomerBeneficiaries();
    }
  },
  mounted() {
    this.accessToken = Cookies.get("token");
    if (this.userRole !== "FAC" && !Cookies.get("reviewBeneficiaries")) {
      this.showModalPopup("modal_popup_review", true);
    }
    let statusCard = utils.getUrlParam("status_card");
    if (statusCard) {
      this.filters.cardStatus = statusCard;
    }
    let productURL = utils.getUrlParam("product");
    if (productURL) {
      this.filters.product = utils.getProductById(
        parseInt(productURL),
        this.customer.products
      );
    } else {
      this.filters.product = this.cardProducts[0];
    }
    if (this.filters.product) {
      if (this.customer.company_id !== 88866) {
        this.loadCustomerBeneficiaries();
      } else {
        this.customerBeneficiaries = [];
      }
      this.selectProduct();
      this.loadDeliveryAddresses();
    }
    this.selectedBeneficiaries = [];
    if (
      this.customer.block_cards
      // || (this.customer.state_owned && this.filters.product.card === 12)
      // || this.userRole === "FAC"
    ) {
      this.cardActions.push("BLOK");
    }

  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.notification-action-small {
  height: auto;
  width: 50%;
  background: #05c591;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  padding: 8px;
  padding-right: 15px;
  text-align: center;
}

.close-button-wrapper {
  width: 60%;
  margin: 50px auto;
  position: relative;
  z-index: 99;
}

.overlayer .close-button-wrapper {
  width: 80% !important;
  margin: 50px auto;
  position: relative;
  z-index: 99;
}

.beneficary-page .left {
  float: left;
  width: auto;
}

.upload-file {
  background-color: #f1f1f1;
  position: inherit;
  cursor: pointer;
}

.beneficary-page .overlayer .container-alb,
.beneficary-page .overlayer .close-button-wrapper {
  width: 60% !important;
}

.wrapper-beneficiaries-actions {
  width: 95%;
  margin: 0 auto;
}

.header-beneficiaries {
  display: inline-block;
  width: 100%;
  position: relative;
}

.header-beneficiaries .right {
  position: absolute;
  bottom: 1rem;
  right: 0;
}

.header-beneficiaries .right a.info:hover>.pop-hover {
  display: block;
}

.selectAll {
  background: #f49400 !important;
  color: #fff !important;
}

.selectAll.isActive {
  background: #fff !important;
  color: #585858 !important;
}

.visibility_hidden {
  visibility: hidden;
}


.visibility_visible {
  visibility: visible;
}

@media (max-width: 768px) {

  .overlayer .close-button-wrapper,
  .beneficary-page .overlayer .close-button-wrapper {
    width: 95% !important;
  }

  .beneficary-page .overlayer .container-alb {
    width: 95% !important;
  }

  .beneficary-page .right .notification-action {
    width: auto !important;
  }
}

@media (max-width: 1003px) {
  .beneficary-page {
    width: 95%;
    margin: 0 auto;
  }

  .beneficary-page .left {
    width: 100%;
  }

  .beneficary-page .right {
    width: 100%;
    margin-top: 20px;
  }

  .beneficary-page .right .notification-action {
    width: auto;
  }

  .header-beneficiaries .right {
    position: relative;
  }

}
</style>
